import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';

import '../css/thumb.css';

function Thumb({ data, index, id }) {
    const thumbRef = useRef(null);
    const imgRef = useRef(null);
    const thumbImageRef = useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
      const img = new Image();
      img.src = `../${data.thumbs[0]}`;
      img.onload = handleImageLoad;
  
      return () => {
        img.onload = null; // Clean up the event handler
      };
    }, [data.thumbs]);
  
    useEffect(() => {
      if (imageLoaded) {
        const tl = gsap.timeline();
        tl.fromTo(imgRef.current, 
          { opacity: 0}, { 
            opacity: 1, 
            duration: 2, 
            ease: 'power3.out',
            onStart: () => {
              imgRef.current.style.opacity = 0;
              thumbImageRef.current.classList.remove('loading');
              imgRef.current.classList.remove('hide');
            }
          }
        )
      }
      else{
        imgRef.current.classList.add('hide');
        imgRef.current.style.opacity = 0;
        thumbImageRef.current.classList.add('loading');
      }
    }, [imageLoaded]);
  
    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    return (
      <Link to={`/collages/collage?index=${index}&id=${id}`} className="thumb" ref={thumbRef}>
        <div className="thumb-image loading" ref={thumbImageRef} >
          <img 
            ref={imgRef} 
            src={data.thumbs[0]} 
            alt={data.title} 
            className={`size-${data.width.toString().replace(/\./g, '_')}x${data.height.toString().replace(/\./g, '_')} hide`}/>
          </div>
          <div className="thumb-title" >
            <h3>{data.title}{data.sold && <span className="text-gray"> (Sold)</span>}</h3>
            <h3>{data.width} x {data.height}</h3>
        </div>
      </Link>
    );
    
}

export default Thumb;   