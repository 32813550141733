import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import Navigation from './Navigation';

import '../css/header.css';

function Header() {
  const location = useLocation();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const btnMenuRef = useRef(null);
  const btnCloseRef = useRef(null);

  // Close the Navigation menu
  const closeMenu = useCallback(() => {
    document.querySelector('nav').classList.add('hide');
    headerRef.current.style.minHeight = "";
    headerRef.current.style.height = "90px";
    btnMenuRef.current.style.display = 'block';
    btnCloseRef.current.style.display = 'none';
    setIsMenuOpen(false);

    const overlay = document.querySelector('.overlay');
    overlay.style.visibility = 'hidden';
  }, []);

  // Open the Navigation menu
  const openMenu = useCallback(() => {

    btnMenuRef.current.style.display = 'none';
    btnCloseRef.current.style.display = 'block';
    setIsMenuOpen(true);

    const nav = document.querySelector('nav');
    nav.style.opacity = 0;
    nav.classList.remove('hide');
    const targetHeight = nav.scrollHeight; // Measure the target height
    
    const timeline = gsap.timeline();
    timeline.fromTo(nav, 
      { 
        height: 0, 
        opacity: 0 
      }, 
      { 
        height: targetHeight, 
        duration: 0.25, 
        ease: 'power2.out',
        onStart: () => {
          headerRef.current.style.minHeight = "90px";
          headerRef.current.style.height = "auto";
        }
      }
    )
    .to(nav, 
      { 
        opacity: 1, 
        duration: 0.5, 
        ease: 'power2.out',
        delay: 0.1
      },"<");


    // Close the menu when clicking outside of it
    const overlay = document.querySelector('.overlay');
    overlay.style.visibility = 'visible';
    const handleClickOutside = (event) => {
      if (event.target === overlay) {
        console.log('click overlay');
        closeMenu();
        document.removeEventListener('click', handleClickOutside);
      }
    };
    document.addEventListener('click', handleClickOutside);
    
  }, [closeMenu]);

  // Set scroll position on page load
  useEffect(() => {

    if(location.pathname === '/about' || location.pathname === '/contact') {
      // reset homepage to top if they navigate to a subpage
      localStorage.setItem('scrollPosition', 0);
    }

    // Restore scroll position when returning to the homepage
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }
    else {   // for all other pages
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  // Update header classes based on location (for sticky header on home page)
  useEffect(() => {
    if (location.pathname === '/') { // For homepage only
      document.querySelector('header').classList.add('fancy');
    }

    return () => {
      if (location.pathname === '/') {
        document.querySelector('header').classList.remove('fancy');
        document.querySelector('header').classList.remove('sticky');
        document.querySelector('header').classList.remove('hidden');
      }
    };

  }, [location.pathname]);

  // Scroll position saving
  useEffect(() => {
    const handleScroll = () => {
      
      // determine scroll direction
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setIsScrollingUp(false);
      } else {
        // Scrolling up
        setIsScrollingUp(true);
      }
      setIsAtTop(currentScrollTop <= 15);
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);

      // Save scroll position when navigating away from the homepage
      if (location.pathname === '/') {
        localStorage.setItem('scrollPosition', currentScrollTop);
      }
    };

    if (location.pathname === '/') { // For homepage only
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (location.pathname === '/') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastScrollTop, location.pathname]);

  // Sticky header
  useEffect(() => {
    if (isAtTop) {
      headerRef.current.classList.remove('sticky');
      headerRef.current.classList.remove('hidden');
    } else if (isScrollingUp) {
      headerRef.current.classList.add('sticky');
      headerRef.current.classList.remove('hidden');
    } else {
      headerRef.current.classList.remove('sticky');
      headerRef.current.classList.add('hidden');
    }
  }, [isScrollingUp, isAtTop]);

  // Menu button
  useEffect(() => {
    const btnMenu = btnMenuRef.current;

    const handleOpenMenu = (event) => {
      event.preventDefault();
      openMenu();
    };

    btnMenu.addEventListener('click', handleOpenMenu);
    btnMenu.addEventListener('touchstart', handleOpenMenu);

    return () => {
      btnMenu.removeEventListener('click', handleOpenMenu);
      btnMenu.removeEventListener('touchstart', handleOpenMenu);
    };
  }, [openMenu]);

  // Close button
  useEffect(() => {
    const btnClose = btnCloseRef.current;

    const handleCloseMenu = (event) => {
      event.preventDefault();
      closeMenu();
      btnMenuRef.current.style.display = 'block';
      btnCloseRef.current.style.display = 'none';
    };

    btnClose.addEventListener('click', handleCloseMenu);
    btnClose.addEventListener('touchstart', handleCloseMenu);

    return () => {
      btnClose.removeEventListener('click', handleCloseMenu);
      btnClose.removeEventListener('touchstart', handleCloseMenu);
    };
  }, [closeMenu]);

  // close menu if it's open when back or logo is clicked
  const handleBackClick = () => {
    if (isMenuOpen) {
      closeMenu();
    }
  };

  return (
      <header ref={headerRef}>

      <div id="header-content">

        <div className="back-button-container hide">
          <Link to="/" id="btn-back" onClick={handleBackClick}></Link>
        </div>

        {location.pathname === '/' ? (
          <div id="Title">
            <h1>Rosemary Carmen Smith</h1>
            <h2>Collage Artist</h2>
          </div>
        ) : (
          <Link to="/" id="Title" onClick={handleBackClick}>
            <div id="Title">
              <h1>Rosemary Carmen Smith</h1>
              <h2>Collage Artist</h2>
            </div>
          </Link>
        )}
        <div id="btn-menu" ref={btnMenuRef}></div>
        <div id="btn-close" ref={btnCloseRef} className='hide'></div>
      </div>

      <Navigation closeMenu={closeMenu}/>

      
    </header>
  );
}

export default Header;