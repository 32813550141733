import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Works from './components/Works';
import Page from './components/Page';
import Viewer from './components/Viewer';

import './css/App.css';


function App() {
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);

  // fetch JSON data
  useEffect(() => {
    fetch('/content.json')
      .then((response) => response.json())
      .then((data) => {
        setJsonData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching JSON:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className='pre loading'>Loading...</div>;
  }

  return (
    <Router>
      <AppContent jsonData={jsonData} />
    </Router>
  );
};

const AppContent = ({ jsonData }) => {

  return (
    <div className="App">
      <div className="overlay"></div>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Works jsonData={jsonData.collections} />} />
          <Route path="/about" element={<Page title="Artist Statement" jsonData={jsonData.about} />} />
          <Route path="/contact" element={<Page title="Contact Info" jsonData={jsonData.contact} />} />
          <Route path="/collages/*" element={<Viewer collectionsData={jsonData.collections} />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
