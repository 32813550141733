import React, {useRef, useEffect} from "react";
import '../css/pagination.css';
import gsap from "gsap";

function SlidePagination ({worksData, currentArtworkIndex, jumpTo}) {

    const paginationRef = useRef(null);
    const totalArtworks = worksData.length;

    // create pagination dots
    useEffect(() => {
        const pagination = paginationRef.current;
        pagination.innerHTML = ''; // Clear any existing nav-dots

        if (Array.isArray(worksData) && worksData.length > 0) {
            const navBlock = document.createElement('div');
            const navDot = document.createElement('div');
            navBlock.className = 'nav-block current slide';
            navDot.className = 'nav-dot';
            navBlock.appendChild(navDot);
            pagination.appendChild(navBlock);

            // Add click event to navBlock
            navBlock.addEventListener('click', (event) => {
                const navBlockWidth = navBlock.offsetWidth;
                const clickX = event.clientX - navBlock.getBoundingClientRect().left;
                const artworkIndex = Math.floor((clickX / navBlockWidth) * totalArtworks);
                jumpTo(artworkIndex);
            });

            // Add drag event to navDot
            let isDragging = false;

            const onMouseMove = (event) => {
                if (!isDragging) return;
                const navBlockWidth = navBlock.offsetWidth;
                const clickX = event.clientX - navBlock.getBoundingClientRect().left;
                const boundedX = Math.max(0, Math.min(clickX, navBlockWidth));
                const xPosition = (boundedX / navBlockWidth) * 100;
                gsap.to(navDot, { left: `${xPosition}%`, duration: 0.1 });
            };

            const onMouseUp = (event) => {
                if (!isDragging) return;
                isDragging = false;
                const navBlockWidth = navBlock.offsetWidth;
                const clickX = event.clientX - navBlock.getBoundingClientRect().left;
                const artworkIndex = Math.floor((clickX / navBlockWidth) * totalArtworks);
                jumpTo(artworkIndex);
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };

            navDot.addEventListener('mousedown', () => {
                isDragging = true;
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', onMouseUp);
            });

        } else {
            console.error("worksData is not an array or is empty");
        }

        return () => {
            pagination.innerHTML = '';
        };
    }, [worksData, jumpTo, totalArtworks]);

    // update current dot position
    useEffect(() => {
        const navDot = paginationRef.current.querySelector('.nav-dot');
        const xPosition = (100 / totalArtworks) * currentArtworkIndex;
        //navDot.style.left = `${xPosition}%`;
        gsap.to(navDot, { 
            left: `${xPosition}%`, 
            duration: 1, 
            ease: 'expo.out', 
        });

    }, [currentArtworkIndex, totalArtworks]);

    return (
        <div className="pagination slide" ref={paginationRef}></div>
    );
} 

export default SlidePagination;