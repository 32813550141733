import React from 'react';
import { Link } from 'react-router-dom';

import '../css/navigation.css';

function Navigation({closeMenu}) {
  
    return (
    <nav className='hide'>
      <div className='nav-content'>
        <ul>
          <li><Link to="/" onClick={closeMenu}><h1>Portfolio</h1></Link></li>
          <li><Link to="/about" onClick={closeMenu}><h1>Artist Statement</h1></Link></li>
          <li><Link to="/contact" onClick={closeMenu}><h1>Contact Info</h1></Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;