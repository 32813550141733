import React, { useEffect, useRef, useState, useCallback } from "react";
import gsap from "gsap";

import Artwork from "./Artwork";
import Pagination from "./Pagination";
import SlidePagination from "./SlidePagination";
import '../css/series.css';

// receives the title, date, series id, and works property from the json data
function Series({ 
          title, 
          date, 
          worksData,
          index,
          currentSeriesIndex,     // state for series index
          currentArtworkIndex,    // state for artwork index at this series level
          updateCurrentArtworkIndex,
    }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [useSlidePagination, setUseSlidePagination] = useState(false);

    const artworkContainerRef = useRef(null);
    const scrollerRef = useRef(null);
    const loaderRef = useRef(null);

    const prevIndex = currentArtworkIndex > 0 ? currentArtworkIndex - 1 : null;
    const nextIndex = currentArtworkIndex < worksData.length - 1 ? currentArtworkIndex + 1 : null;

    const gap = 24; // pixel gap between artwork

    // animates the artwork container to the specified index
    const slideTo = useCallback((index) => {

        const xPosition = (windowWidth * index) + (gap * index);
        gsap.to(scrollerRef.current, { 
            scrollLeft: xPosition, 
            duration: 1, 
            ease: 'expo.out', 
        });

    }, [windowWidth]);

    // jumps to the specified index without animation
    const jumpTo = useCallback((index) => {
      const scroller = scrollerRef.current;
      const xPosition = (windowWidth * index) + (gap * index);

      // Temporarily disable smooth scrolling to jump to xPosition
      scroller.style.scrollBehavior = 'auto';
      scroller.scrollLeft = xPosition;
      scroller.style.scrollBehavior = 'smooth';

    }, [windowWidth, gap]);

    // determines if slideination should be used based on the total width of the pagination dots
    const calculatePaginationType = useCallback((screenWidth) => {
      const dotWidth = 20; // Estimate the width of a single dot (including margin/padding)
      const totalDotsWidth = worksData.length * dotWidth;
      const maxAllowedWidth = 0.75 * screenWidth;

      if (totalDotsWidth > maxAllowedWidth) {
        setUseSlidePagination(true);
      } else {
        setUseSlidePagination(false);
      }
  }, [worksData]);

    // keyboard listeners for prev / next navigation
    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft' && prevIndex !== null && currentSeriesIndex === index) {
            slideTo(prevIndex);
        } else if (event.key === 'ArrowRight' && nextIndex !== null && currentSeriesIndex === index) {
            slideTo(nextIndex);
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [prevIndex, nextIndex, slideTo, currentSeriesIndex, index]);
    
    // updates the current artwork id based on scroll position for scroll snapping
    useEffect(() => {
      let scrollTimeout;

      const handleScroll = () => {

        const scrollLeft = scroller.scrollLeft;
        const index = Math.round(scrollLeft / (windowWidth + gap));
    
        // Clear the previous timeout
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }
    
        // Set a timeout to detect scroll end
        scrollTimeout = setTimeout(() => {
          if (index !== currentArtworkIndex) {
            updateCurrentArtworkIndex(index);
          }

        }, 100); // Adjust the timeout duration as needed
    
      };
      const scroller = scrollerRef.current;
      scroller.addEventListener('scroll', handleScroll);
    
      return () => {
        scroller.removeEventListener('scroll', handleScroll);
        if (scrollTimeout) {
          clearTimeout(scrollTimeout);
        }
      };
    
    }, [windowWidth, gap, currentArtworkIndex, updateCurrentArtworkIndex]);

    // update window width on resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [calculatePaginationType]);

    // update width, gap, and scrollLeft on window resize
    useEffect(() => {
      const scroller = scrollerRef.current;
      const artworkContainer = artworkContainerRef.current;
      
      const totalWidth = (worksData.length * windowWidth) + ((worksData.length - 1) * gap);
      artworkContainer.style.width = `${totalWidth}px`;
      artworkContainer.style.gap = `${gap}px`;
     
      const xPosition = (windowWidth * currentArtworkIndex) + (gap * currentArtworkIndex);
      if (scroller.scrollLeft !== xPosition) {
        // Temporarily disable smooth scrolling to jump to xPosition
        scroller.style.scrollBehavior = 'auto';
        scroller.scrollLeft = xPosition;
        scroller.style.scrollBehavior = 'smooth';
      }

      calculatePaginationType(windowWidth);

    }, [worksData, windowWidth, currentArtworkIndex, calculatePaginationType]);
   
    return (
    <>
        <div className="series">
          <div className="series-title">
              <h1>{title}</h1>
              <h2>{date}</h2>
          </div>
          <div className="artwork-container-scroller" ref={scrollerRef}>
            <div className='artwork-container' ref={artworkContainerRef}>
              {worksData.map((artwork, index) => (
                  <Artwork key={index} artworkData={artwork} loaderRef={loaderRef} />
              ))}
            </div>
          </div>
          {useSlidePagination ? (
                <SlidePagination worksData={worksData} currentArtworkIndex={currentArtworkIndex} jumpTo={jumpTo} />
            ) : (
                <Pagination worksData={worksData} currentArtworkIndex={currentArtworkIndex} jumpTo={jumpTo} />
            )}
        </div>
    </>
    );
}

export default Series;