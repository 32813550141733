import React, {useRef, useState, useCallback, useEffect} from "react";
import gsap from "gsap";

import '../css/artwork.css';

function Artwork({ artworkData }) {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [backgroundImageStyle, setBackgroundImageStyle] = useState({});
    const imageRef = useRef(null);
    const loaderRef = useRef(null);



    ///// IMAGE TRANSITIONS /////

    const animateImageIn = () => {

        if (imageRef.current) {
        gsap.fromTo(
            imageRef.current,
            { opacity: 0, x: 0 },
            { opacity: 1, duration: 0.5, ease: 'power3.out' }
        );
        }
    };

    const animateImageOut = useCallback((onComplete) => {
        if (imageRef.current) {
        gsap.to(imageRef.current, {
            opacity: 0,
            duration: 0.1,
            ease: 'power3.out',
            onComplete: onComplete
        });
        }
    }, []);

    const showLoader = useCallback(() => {
        imageRef.current.style.opacity = 0;
        imageRef.current.style.transform = 'translateX(0)';
        loaderRef.current.classList.remove('hide');
    }, [loaderRef]);


    ///// IMAGE LOADING /////


    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    // handles lazy loading of images
    useEffect(() => {
        if (isVisible) {
            showLoader();
            const img = new Image();
            img.src = `../${artworkData.images[currentImageIndex]}`;
            setBackgroundImageStyle({backgroundImage: `url(../${artworkData.images[currentImageIndex]})`});
            img.onload = handleImageLoad;

            return () => {
                img.onload = null; // Clean up the event handler
            };
        }
    }, [currentImageIndex, artworkData.images, isVisible, showLoader]);

    useEffect(() => {
        if (imageLoaded) {
            loaderRef.current.classList.add('hide');
            animateImageIn();
        } else {
            showLoader();
        }
    }, [imageLoaded, loaderRef, showLoader]); 

    // Intersection Observer for lazy loading
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );
    
        const currentImageRef = imageRef.current;
    
        if (currentImageRef) {
            observer.observe(currentImageRef);
        }
    
        return () => {
            if (currentImageRef) {
                observer.unobserve(currentImageRef);
            }
        };
    }, []);

    ///// BUTTON CLICK /////

    // Toggle between the two images
    const handleBtnSizeClick = () => {
        if (currentImageIndex === 0) {
            animateImageOut(() => {
                setCurrentImageIndex(1);
                setImageLoaded(false); // Reset imageLoaded to false for the new image
              });
        }
        else {
            animateImageOut(() => {
                setCurrentImageIndex(0);
                setImageLoaded(false); // Reset imageLoaded to false for the new image
              });
        }
    }
    
    return (
        <>
        {/* <div className="loading full-loader" ref={loaderRef}></div> */}
        <div className="artwork">
        <div className="loading image-loader" ref={loaderRef}></div>
            <div className='image-container'>
                <div
                className="full-image"
                style={backgroundImageStyle}
                ref={imageRef}
                ></div>
            </div>

            <div className="artwork-info">
                <h3>{artworkData.title}{artworkData.sold && <span className="text-gray"> (Sold)</span>}</h3>
                <div className="btn-size" onClick={handleBtnSizeClick}></div>
                <h3>{artworkData.width} x {artworkData.height}</h3>
            </div>
        </div>
      </>
    );
}

export default Artwork;