import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import '../css/footer.css';

function Footer() {
  const location = useLocation();
  const footerContentRef = useRef(null);
  const bioRef = useRef(null);

  useEffect(() => {
    const bio = bioRef.current
    const footerContent = footerContentRef.current;
    if(location.pathname === '/about' || location.pathname === '/contact') {
      bio.classList.add('hide');
      footerContent.classList.add('slim');

    }

    return () => {
      bio.classList.remove('hide');
      footerContent.classList.remove('slim');
    };
  },[location.pathname]);

  return (
    <footer>
      <div className='footer-content' ref={footerContentRef}>
        <h3 ref={bioRef}>Rosemary lives and works in Fairfax, California. She creates collages by hand from cut paper and found images. Each piece is one of a kind.</h3>
        <h4>© 2024</h4>
      </div>
    </footer>
  );
}

export default Footer;