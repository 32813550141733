import React, {useRef, useEffect} from "react";
import '../css/pagination.css';

function Pagination ({worksData, currentArtworkIndex, jumpTo}) {

    const paginationRef = useRef(null);

    // create pagination dots
    useEffect(() => {
        const pagination = paginationRef.current;
        pagination.innerHTML = ''; // Clear any existing nav-dots

        if (Array.isArray(worksData) && worksData.length > 0) {
            worksData.forEach((_, index) => {
                const navBlock = document.createElement('div');
                const navDot = document.createElement('div');
                navBlock.className = 'nav-block';
                navDot.className = 'nav-dot';
                navBlock.addEventListener('click', () => { 
                    jumpTo(index);
                });
                navBlock.appendChild(navDot);
                pagination.appendChild(navBlock);
            });
        } else {
            console.error("worksData is not an array or is empty");
        }

        return () => {
            pagination.innerHTML = '';
        };
    }, [worksData, jumpTo]);

    // update current dot
    useEffect(() => {
        const navBlocks = paginationRef.current.querySelectorAll('.nav-block');

        // reset the classes
        navBlocks.forEach((navBlock, index) => {

            navBlock.classList.remove('current');
        });

        // mark the current dot
        if (navBlocks[currentArtworkIndex]) {
            navBlocks[currentArtworkIndex].classList.add('current');
        }

    }, [currentArtworkIndex]);

    return (
        <div className="pagination" ref={paginationRef}></div>
    );
} 

export default Pagination;