import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import gsap from 'gsap';

import Series from './Series';
import '../css/page.css';
import '../css/viewer.css';

// receives the collections property from the json data
function Viewer({ collectionsData }) {

  // Get the query parameters from the URL to determine which collage to display
  const location = useLocation(); // Get the location object
  const queryParams = new URLSearchParams(location.search); // Parse the query parameters
  const seriesIndexParam = queryParams.get('index'); // Get the 'index' parameter from the query string
  const artworkIDParam = queryParams.get('id'); // Get the 'id' parameter from the query string

  const navigate = useNavigate();

  const [currentSeriesIndex, setCurrentSeriesIndex] = useState(Number(seriesIndexParam));
  const [currentArtworkID, setCurrentArtworkID] = useState(Number(artworkIDParam));
  const [currentArtworkIndexes, setCurrentArtworkIndexes] = useState(
    collectionsData.map((_, index) => (index === Number(seriesIndexParam) ? Number(artworkIDParam) : 0))
  );

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const seriesContainerRef = useRef(null);
  const scrollerRef = useRef(null);

  const prevIndex = currentSeriesIndex > 0 ? currentSeriesIndex - 1 : null;
  const nextIndex = currentSeriesIndex < collectionsData.length - 1 ? currentSeriesIndex + 1 : null;

  const gap = 200; 

  // updates the URL query parameters when the series index or artwork ID changes
  useEffect(() => {

    const params = new URLSearchParams(location.search);
    params.set('index', currentSeriesIndex);
    params.set('id', currentArtworkIndexes[currentSeriesIndex]);
    navigate({ search: params.toString() });

  }, [currentSeriesIndex, currentArtworkIndexes, location.search, navigate]);


  // updates the array of artwork index states when one of them changes
  // called from inside each Series component
  const updateCurrentArtworkIndex = (seriesIndex, newIndex) => {
    setCurrentArtworkIndexes(prevIndexes => {
      const newIndexes = [...prevIndexes];
      newIndexes[seriesIndex] = newIndex;
      return newIndexes;
    });
  };

  // updates the current series index based on scroll position for scroll snapping
  useEffect(() => {
    let scrollTimeout;

    const handleScroll = () => {

      const scrollTop = scroller.scrollTop;
      const scrolledToIndex = Math.round(scrollTop / (windowHeight + gap));
  
      // Clear the previous timeout
      if (scrollTimeout) {
          clearTimeout(scrollTimeout);
      }
  
      // Set a timeout to detect scroll end
      scrollTimeout = setTimeout(() => {
        setCurrentSeriesIndex(scrolledToIndex);
      }, 100); // Adjust the timeout duration as needed
  
    };
    const scroller = scrollerRef.current;
    scroller.addEventListener('scroll', handleScroll);
  
    return () => {
      scroller.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  
  }, [windowHeight]);

  // update window height on resize
  useEffect(() => {
    const handleResize = () => {
        setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  // update width, gap, and scrollTop on window resize
  useEffect(() => {
    const scroller = scrollerRef.current;
    const seriesContainer = seriesContainerRef.current;
    
    const totalHeight = (collectionsData.length * windowHeight) + ((collectionsData.length - 1) * gap);
    seriesContainer.style.height = `${totalHeight}px`;
    seriesContainer.style.gap = `${gap}px`;
    
    const yPosition = (windowHeight * currentSeriesIndex) + (gap * currentSeriesIndex);
    if (scroller.scrollLeft !== yPosition) {
      // Temporarily disable smooth scrolling to jump to yPosition
      scroller.style.scrollBehavior = 'auto';
      scroller.scrollTop = yPosition;
      scroller.style.scrollBehavior = 'smooth';
    }

  }, [collectionsData, windowHeight, currentSeriesIndex]);

  // hide the header and footer
  useEffect(() => {
    // hide the header and footer
    document.querySelector('header').style.display = 'none';
    document.querySelector('footer').style.display = 'none';

    // adjust Main to remove padding
    document.querySelector('main').classList.add('collage');

    // turn off scrolling
    document.body.style.overflow = 'hidden';

    return () => {
      // show the header and footer
      document.querySelector('header').style.display = 'block';
      document.querySelector('footer').style.display = 'block';

      // adjust Main to remove padding
      document.querySelector('main').classList.remove('collage');

      // turn scrolling back on
      document.body.style.overflow = 'scroll';
    };
  }, []);

  // animates the artwork container to the specified index
  const slideTo = useCallback((index) => {

    const yPosition = (windowHeight * index) + (gap * index);
    gsap.to(scrollerRef.current, { 
        scrollTop: yPosition, 
        duration: 1, 
        ease: 'expo.out', 
    });

}, [windowHeight]);

  // keyboard listeners for up / down navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp' && prevIndex !== null) {
          slideTo(prevIndex);
      } else if (event.key === 'ArrowDown' && nextIndex !== null) {
          slideTo(nextIndex);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [prevIndex, nextIndex, slideTo]);

  return (
    <>
        <Link to="/" id="btn-close-viewer"></Link>
        <div className="series-container-scroller" ref={scrollerRef}>
          <div className='series-container' ref={seriesContainerRef}>
            {collectionsData.map((series, index) => (
                <Series 
                  key={index}
                  title={collectionsData[index].title} 
                  date={collectionsData[index].date} 
                  worksData={collectionsData[index].works}
                  index={index}
                  currentSeriesIndex={currentSeriesIndex}
                  currentArtworkIndex={currentArtworkIndexes[index]}
                  updateCurrentArtworkIndex={(newIndex) => updateCurrentArtworkIndex(index, newIndex)}
                  currentArtworkID={currentArtworkID} 
                  setCurrentArtworkID={setCurrentArtworkID}
                />
            ))}
          </div>
        </div>

    </>
  )
}

export default Viewer;